// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery'
require("custom/custom_script")

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
    if (window.scrollY > 10) {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').style.position = "fixed";
        document.getElementById('navbar_top').classList.add('fixed-menu');
      }
    } else {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').style.position = "absolute";
        document.getElementById('navbar_top').classList.remove('fixed-menu');
      }
    } 
  });
});

Rails.start()
//Turbolinks.start()
ActiveStorage.start()
